<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <a href="#" class="flex-column-auto">
            <img :src="logo" style="width: 5rem" />
          </a>
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mt-10 mb-5 text-white">Ein Fehler ist aufgetreten.</h3>
          </div>
          <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-15">
            <div class="opacity-70 font-weight-bold text-white">
              © {{ new Date().getFullYear() }} SKR Reisen
            </div>
          </div>
        </div>
      </div>
      <div style="margin: auto">
        <div>
          <h1>
            {{ pageUrl }}
          </h1>
          <h2>Du bist nicht berechtigt diese Seite zu sehen</h2>
          <template v-if="requiredRoles && requiredRoles.length > 0">
            <p>Diese Rollen fehlen:</p>
            <ul :key="role" v-for="role in requiredRoles">
              <li>{{ role }}</li>
            </ul>
          </template>
          <template v-if="roles && roles.length > 0">
            <p>Du hast folgende Rollen zugewiesen:</p>
            <ul :key="role" v-for="role in roles">
              <li>{{ role }}</li>
            </ul>
          </template>
          <template v-else>
            <p>Du hast keine Rollen zugewiesen</p>
          </template>
          <p>
            Bitte wende dich an:
            <a href="mailto:lena.kortendieck@skr.de">lena.kortendieck@skr.de</a>
          </p>
          <button class="btn btn-light-primary btn-bold" @click="onLogout">Abmelden</button>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { LOGOUT } from '@/core/user/stores/auth.module.js';
import { mapState } from 'vuex';

export default {
  props: {
    pageUrl: {
      default: '404',
    },
    requiredRoles: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      roles: state => state.auth.account.idTokenClaims.roles,
    }),
    backgroundImage() {
      return process.env.BASE_URL + 'media/bg/bg-2.jpg';
    },
    logo() {
      return process.env.BASE_URL + 'media/logos/logo-skr.svg';
    },
    msLoginButton() {
      return process.env.BASE_URL + 'media/svg/social-icons/ms-symbollockup_signin_dark.svg';
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
    },
  },
};
</script>
